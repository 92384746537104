//PACKS
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import axios from "axios";
import emailjs from "@emailjs/browser";
import { motion } from "framer-motion";
import { useWindowWidth } from "@react-hook/window-size";

//COMPONENTS
import Navbar from "./Navbar";
import Contact from "./Contact";
import FormModal from "./FormModal";
import CartItem from "./CartItem";
import Loading from "./Loading";
//import CouponForm from "./Coupon";

//STYLES
import "../styles/form.scss";

//ASSETS
import { api } from "../assets/json/api";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import { CaretRight, CaretLeft, Gift } from "phosphor-react";

const formVariants = {
  initial: {
    opacity: 1,
    pointerEvents: "all",
  },
  sent: {
    opacity: 0.25,
    pointerEvents: "none",
  },
};

const emptyOrder = {
  email: "",
  phone_number: "",
  name: "",
  address: "",
  product_ids: [],
};

const emptyCodeCoupon = {
  coupon: "",
};

const emptyText = {
  light: "",
  bold: "",
};

export default function Form(props) {
  //navigation hook
  let navigate = useNavigate();

  //window width
  const windowWidth = useWindowWidth();

  //store
  const cart = useStoreState((state) => state.cart);
  const resetCart = useStoreActions((actions) => actions.resetCart);

  //states
  const [text, setText] = useState(emptyText);
  const [order, setOrder] = useState(emptyOrder);
  const [sent, setSent] = useState(false);
  const [modal, setModal] = useState(false);
  const [display, setDisplay] = useState(null);
  const [codeCoupon, setCodeCoupon] = useState(emptyCodeCoupon);
  const [displayCoupon, setDisplayCoupon] = useState("");
  const [totPrice, setTotPrice] = useState(emptyPriceInstatier());
  const [listCart, setListCart] = useState(
    cart &&
      cart.map((item, index) => (
        <div key={index}>
          <CartItem
            data={item}
            key={index}
            totPrice={emptyPriceInstatier().value}
          />
        </div>
      ))
  );

  function emptyPriceInstatier() {
    if (!cart[0]) {
      const emptyPrice = {
        value: "",
      };
      return emptyPrice;
    } else {
    }
    const emptyPrice = {
      value: cart[0].product.price,
    };
    return emptyPrice;
  }

  //check if coupon is valid
  async function validateCoupon(couponEvent) {
    couponEvent.preventDefault();
    let temp = 0;
    let couponText = "";
    cart.map((item) => (temp += parseInt(item.product.price)));
    await axios.post(api + "check_coupon", codeCoupon).then((res) => {
      const response = res.data;
      if (response.error) {
        const error = response.error;
        triggerModel(error, "");
        return;
      }else if (response.amount_off !== "") {
        const amount_off = response.amount_off
        couponText = amount_off/100 + "€ di sconto applicato!"        
        temp -= parseInt(amount_off)/100
        let discountedPrice = {...totPrice};
        discountedPrice["value"] = temp;
        setTotPrice(discountedPrice);
        setDisplayCoupon(<p>{couponText}</p>);
      } else {
        const percent_off = response.percent_off;
        couponText = "Sconto dell' " + percent_off + "% applicato!";
        temp -= temp * (parseInt(percent_off) / 100);
        setDisplayCoupon(<p>{couponText}</p>);
        let discountedPrice = { ...totPrice };
        discountedPrice["value"] = temp;
        setTotPrice(discountedPrice);
      }
    });
  }
  //handle input of coupon
  function handleCoupon(couponEvent) {
    let valueName = couponEvent.target.name;
    let value = couponEvent.target.value;
    let temp = codeCoupon;
    temp[valueName] = value;
    setCodeCoupon(temp);
  }

  //display "something's wrong" modal
  function triggerModel(light, bold) {
    let temp = text;
    temp["light"] = light;
    temp["bold"] = bold;
    setText(temp);
    setModal(true);
  }

  //close "something's wrong" modal
  function handleModal() {
    setModal(false);
  }

  //send order to backend and redirect to stripe
  async function sendOrder(event) {
    //no html mumbo jumbo
    event.preventDefault();
    if (!validate() || order.name == "") {
      triggerModel("Sembra che tu abbia perso un campo","Per favore riprova")
      //check if inputs are valid
    } else {
      try {
        console.log("SUNT AICIII1");
        emailjs
          .send(
            "service_n3h92wm",
            "template_lipkadg",
            {
              name: order.name,
              email: order.email,
            },
            "user_4IMgDwhSeaqzMU4kOMUfO"
          )
          .then(
            (response) => {

              //console.log("SUCCESS!", response.status, response.text);
              //console.log("SUNT AICIII2");
            },
            (err) => {
              //console.log("FAILED...", err);
              //console.log("SUNT AICIII3");
            }
          );
        //console.log("SUNT AICIII4");
        let temp = { ...order };

        temp["coupon"] = codeCoupon.coupon;
        //console.log("order:", temp);
        const response = await axios.post(api + "orders/", {
          order: temp,
        });
        setSent(true);
        //console.log("order sent", response);
        //console.log(Promise.resolve(response.data.link));
        window.location.replace(response.data.link);
      } catch (error) {
        console.error(error);
      }
    }
  }

  // handle input data on keystroke
  function handleInput(event) {
    let valueName = event.target.name;
    let value = event.target.value;
    let temp = order;
    temp[valueName] = value;
    setOrder(temp);
  }

  //validate inputs on submit
  function validate() {
    console.log(isEmail(order.email));
    console.log(isMobilePhone(order.phone_number));
    return (
      isEmail(order.email) && isMobilePhone(order.phone_number)
      // order.adress.length
    );
  }

  //on mount/unmount
  useEffect(() => {
    if (cart.length === 0) {
      //check if there are items in the basket
      navigate("/");
    } else {
      // if so, load them into the order
      cart.forEach((item) => {
        order.product_ids.push(item.product.id);
      });
      // put in order only last product
      order.product_ids = order.product_ids.slice(-1);
    }

    setDisplayCoupon(
      <form
        onSubmit={(couponEvent) => {
          validateCoupon(couponEvent);
        }}
        className="formCoupon"
      >
        <motion.div
          initial="initial"
          variants={formVariants}
          animate={sent ? "sent" : "initial"}
        >
          <input
            type="coupon"
            placeholder="Coupon"
            name="coupon"
            onChange={(couponEvent) => {
              handleCoupon(couponEvent);
            }}
          />
        </motion.div>
        <button className="couponButton" type="submit">
          <Gift size={30} />
        </button>
      </form>
    );
    return () => {
      //clear the basket on unmount
      resetCart();
    };
  }, []);

  useEffect(() => {
    //console.log(windowWidth);
    if (!cart) {
      return;
    }
    windowWidth <= 768 ? setDisplay(mobile()) : setDisplay(desktop());
    //console.log("display:", display);
  }, [windowWidth, cart, listCart]);

  useEffect(() => {
    setListCart(
      cart &&
        cart.map((item, index) => (
          <CartItem data={item} totPrice={totPrice.value} />
        ))
    );
  }, [totPrice]);

  function mobile() {
    return (
      <>
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={props.pageVariants}
          className="page purchase"
        >
          {true && (
            <>
              <Navbar />
              <Link to={"/"}>
                <div className="backLink">
                  <CaretLeft size={22} />
                  <p>Inserisci i tuoi dati</p>
                </div>
              </Link>
              <div className="separatorSmall" />
              <div className="formCart">{listCart}</div>
              <div className="separatorSmall" />
              <form
                onSubmit={(event) => {
                  sendOrder(event);
                }}
              >
                <motion.div
                  initial="initial"
                  variants={formVariants}
                  animate={sent ? "sent" : "initial"}
                  className="formInputs"
                >
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={(event) => {
                      handleInput(event);
                    }}
                  />
                  <input
                    type="text"
                    placeholder="Nome"
                    name="name"
                    onChange={(event) => {
                      handleInput(event);
                    }}
                  />
                  <input
                    type="tel"
                    placeholder="Telefono"
                    name="phone_number"
                    onChange={(event) => {
                      handleInput(event);
                    }}
                  />
                </motion.div>
                <div className="separatorSmall" />
                <button className="purchaseButton" type="submit">
                  <p>Pagamento</p>
                  <CaretRight size={21} />
                </button>
              </form>
              <div className="separatorBig" />
              <div className="coupon">{displayCoupon}</div>
              <div className="separatorBig" />
              <p className="gdprParagraph">
                Verrai reindirizzato su Stripe per completare l’acquisto
              </p>
              <div className="separatorSmall" />
              <p className="gdprParagraph">
                Cliccando “Pagamento” accetti la nostra privacy policy
              </p>
              <div className="separatorBig" />
              <Contact />
              <div className="separatorBig" />
            </>
          )}
        </motion.div>
      </>
    );
  }

  function desktop() {
    return (
      <>
        {" "}
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={props.pageVariants}
          className="page purchase"
        >
          {true && (
            <>
              <Navbar />
              <div className="backLinkContainer">
                <Link to={"/"}>
                  <div className="backLink">
                    <CaretLeft size={22} />
                    <p>Inserisci i tuoi dati</p>
                  </div>
                </Link>
              </div>
              <div className="separatorBig" />
              <div className="formGrid">
                <form
                  onSubmit={(event) => {
                    sendOrder(event);
                  }}
                >
                  <motion.div
                    initial="initial"
                    variants={formVariants}
                    animate={sent ? "sent" : "initial"}
                    className="formInputs"
                  >
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={(event) => {
                        handleInput(event);
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Nome"
                      name="name"
                      onChange={(event) => {
                        handleInput(event);
                      }}
                    />
                    <input
                      type="tel"
                      placeholder="Telefono"
                      name="phone_number"
                      onChange={(event) => {
                        handleInput(event);
                      }}
                    />
                  </motion.div>
                  <button className="purchaseButton" type="submit">
                    <p>Pagamento</p>
                    <CaretRight size={21} />
                  </button>
                </form>
                <div className="formCart">{listCart}</div>
                <div className="coupon">{displayCoupon}</div>
              </div>
              <div className="separatorBig" />
              <p className="gdprParagraph">
                Verrai reindirizzato su Stripe per completare l’acquisto
              </p>
              <p className="gdprParagraph">
                Cliccando “Pagamento” accetti la nostra privacy policy
              </p>
              <div className="separatorBig" />
              <Contact />
              <div className="separatorBig" />
            </>
          )}
        </motion.div>
      </>
    );
  }

  return (
    <>
      <Loading sent={sent} />
      <FormModal
        display={modal}
        handleModal={handleModal.bind(this)}
        light={text.light}
        bold={text.bold}
      />
      {display}
    </>
  );
}
