import React, { useState, useEffect } from "react";
import "../styles/productDescription.scss";
export default function ProductDescription(props) {
  const [display, setDisplay] = useState(null);
  const [description, setDescription] = useState(null);
  //display="mobile" description={description}

  //UPDATE DESCRIPTION FROM PROPS
  useEffect(() => {
    if (props.description.length > 0) {
      setDescription(props.description);
      // console.log("description updated:", props.description);
    }
  }, [props]);

  //UPDATE DISPLAY
  useEffect(() => {
    if (props.display === "mobile") {
      setDisplay(mobile());
    } else if (props.display === "desktop") {
      setDisplay(desktop());
    }
  }, [props.display, description]);

  function mobile() {
    return (
      <div className="productDescription">
        {description &&
          description.map((paragraph, index) => (
            <div key={index} className="productDescriptionParagraph">
              {paragraph.paragraph.title && (
                <p className="productDescriptionParagraphTitle">
                  {paragraph.paragraph.title}
                </p>
              )}
              {paragraph.paragraph.text && (
                <p className="productDescriptionParagraphText">
                  {paragraph.paragraph.text}
                </p>
              )}
              {paragraph.bullets && (
                <div className="productDescriptionParagraphBulletContainer">
                  {paragraph.bullets.map((bullet, bulletIndex) => (
                    <p
                      key={bulletIndex}
                      className="productDescriptionParagraphBullet"
                    >
                      {bullet.text}
                    </p>
                  ))}
                </div>
              )}
              {paragraph.paragraph_photo.length > 0 && (
                <div className="productDescriptionParagraphImage">
                  <img src={paragraph.paragraph_photo[0].link} />
                </div>
              )}
              <div className="productDetailsSeparator" />
            </div>
          ))}
      </div>
    );
  }

  function desktop() {
    return (
      <div className="productDescription">
        {description &&
          description.map((paragraph, index) => (
            <div key={index} className="productDescriptionParagraph">
              {paragraph.paragraph.title && (
                <p className="productDescriptionParagraphTitle">
                  {paragraph.paragraph.title}
                </p>
              )}
              {paragraph.paragraph.text && (
                <p className="productDescriptionParagraphText">
                  {paragraph.paragraph.text}
                </p>
              )}
              {paragraph.bullets && (
                <div className="productDescriptionParagraphBulletContainer">
                  {paragraph.bullets.map((bullet, bulletIndex) => (
                    <p
                      key={bulletIndex}
                      className="productDescriptionParagraphBullet"
                    >
                      {bullet.text}
                    </p>
                  ))}
                </div>
              )}
              {paragraph.paragraph_photo.length > 0 && (
                <div className="productDescriptionParagraphImage">
                  <img src={paragraph.paragraph_photo[0].link} />
                </div>
              )}
              <div className="productDetailsSeparator" />
            </div>
          ))}
      </div>
    );
  }

  return <>{display}</>;
}
