import React from "react";
import "../styles/descriptionCard.scss";
import { Link } from "react-router-dom";
export default function DescriptionCard(props) {
  return (
    <div className="descriptionCard">
      {/* <Link to="/mission"> */}
      <div className="descriptionCardImage">
        <img src={props.data.image} alt="descriptionImage" />
      </div>
      {/* </Link> */}
      <div className="descriptionCardText">
        <p>{props.data.text}</p>
      </div>
    </div>
  );
}
