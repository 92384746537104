//PACKS
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useWindowWidth } from "@react-hook/window-size";

//COMPONENTS
import ItemCard from "./ItemCard";
import Paragraph from "./Paragraph";

//STYLES
import "../styles/items.scss";

//ASSETS
import { CaretRight } from "phosphor-react";
import { api } from "../assets/json/api";

export default function Items(props) {
  const navigate = useNavigate();

  //items loaded by api
  const [items, setItems] = useState(null);

  //will be updated depending on window width
  const [displayedItems, setDisplayedItems] = useState(null);

  //window width
  const windowWidth = useWindowWidth();
  const [itemNum, setItemNum] = useState();
  useEffect(() => {
    //SET THE NUMBER OF DISPLAYED ITEMS, WHEN THE ITEM LIST IS FETCHED
    if (!items) {
      return;
    }
    if (windowWidth >= 1366) {
      setItemNum(3);
    } else if (windowWidth < 1366 && windowWidth >= 768) {
      setItemNum(4);
    } else {
      setItemNum(3);
    }
    //windowWidth <= 1366 ? setDisplay(mobile()) : setDisplay(desktop());
    //console.log("display:", display);
  }, [items, windowWidth]);

  useEffect(() => {
    if (!items) {
      return;
    }
    setDisplayedItems(items.slice(0, itemNum));
  }, [itemNum, items]);

  useEffect(() => {
    axios
      .get(api + "products")
      .then((res) => {
        // console.log("items in home", res.data.products);
        setItems(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="items">
      <Paragraph data={props.data} />
      <div className="itemCards">
        {displayedItems &&
          displayedItems.map((item, index) => (
            <li key={index} style={{ listStyle: "none" }}>
              <ItemCard data={item} />
            </li>
          ))}
      </div>
      <div className="showMoreItemsButtonContainer">
        <button
          className="showMoreItemsButton"
          onClick={() => {
            navigate("/products");
          }}
        >
          <p>Tutti i prodotti</p>
          <CaretRight size={21} />
        </button>
      </div>
    </div>
  );
}
