import React, { useState, useEffect } from "react";
import axios from "axios";
import ItemCard from "./ItemCard";
import { motion } from "framer-motion";
import Navbar from "./Navbar";
import Paragraph from "./Paragraph";
import Contact from "./Contact";
import "../styles/allProducts.scss";

//ASSETS
import { api } from "../assets/json/api";
import { paragraphs } from "../assets/json/parahraphs";

export default function AllProducts(props) {
  const [items, setItems] = useState(null);

  useEffect(() => {
    axios
      .get(api + "products")
      .then((res) => {
        console.log(res.data.products);
        setItems(res.data.products);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={props.pageVariants}
      className="page allProducts"
    >
      <Navbar />
      <div className="allProductsParagraph">
        <Paragraph data={paragraphs.items} />
      </div>

      <div className="separatorSmall" />
      <div className="itemCards">
        {items &&
          items.map((item, index) => (
            <li key={index} style={{ listStyle: "none" }}>
              <ItemCard data={item} />
            </li>
          ))}
      </div>
      <div className="separatorBig" />
      <Contact />
      <div className="separatorBig" />
    </motion.div>
  );
}
