import description1 from "../images/description1.JPG";
import description2 from "../images/description2.JPG";
import description3 from "../images/description3.JPG";

export const description = [
  {
    text: "Impari da casa grazie alle nostre lezioni online. Hai a disposizione tanti appuntamenti tra cui scegliere.",
    image: description1,
  },
  {
    text: "Le lezioni si svolgono in piccoli gruppi, guidate da un educatore dedicato, per un apprendimento su misura.",
    image: description2,
  },
  {
    text: "Ogni kit è un’avventura da vivere e rivivere. Crea, personalizza e riutilizza il tuo kit quante volte vuoi.",
    image: description3,
  },
];
