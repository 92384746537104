export const paragraphs = {
  description: {
    title1: "Un'esperienza",
    title2: "online unica",
  },
  items: {
    title1: "Scegli il tuo kit",
    description: "Ogni kit include un corso online con un docente esperto",
  },
};
