import React from "react";
import "../styles/paragraph.scss";
export default function Paragraph(props) {
  return (
    <div className="paragraph">
      {props.data.title1 && (
        <p className="paragraphTitle">{props.data.title1}</p>
      )}
      {props.data.title2 && (
        <p className="paragraphTitle">{props.data.title2}</p>
      )}
      {props.data.description && (
        <p className="paragraphDescription">{props.data.description}</p>
      )}
    </div>
  );
}
