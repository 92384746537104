import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "../styles/formModal.scss";

const variants = {
  opened: {
    opacity: 1,
    y: 0,
    pointerEvents: "all",
  },
  closed: {
    opacity: 0,
    y: -50,
    pointerEvents: "none",
  },
};
const bgVariants = {
  opened: {
    opacity: 1,
    pointerEvents: "all",
  },
  closed: {
    opacity: 0,
    pointerEvents: "none",
  },
};

export default function FormModal(props) {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    //console.log("received props", props.display);
    setDisplay(props.display);
  }, [props]);

  return (
    <motion.div
      initial="closed"
      variants={bgVariants}
      animate={display ? "opened" : "closed"}
      className="modalBg"
      onClick={() => {
        props.handleModal();
      }}
    >
      <motion.div
        initial="closed"
        variants={variants}
        animate={display ? "opened" : "closed"}
        className="modal"
      >
        <p style={{ color: "#66737C" }}>{props.light}</p>
        <p>{props.bold}</p>
      </motion.div>
    </motion.div>
  );
}
