import React, { useState, useEffect } from "react";
import "../styles/productImage.scss";
export default function ProductImage(props) {
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    setPhoto(props.photo);
  }, [props]);

  return (
    <>
      {photo && (
        <div
          className="productImage"
          style={{ backgroundImage: `url(${photo})` }}
        ></div>
      )}
    </>
  );
}
