import React from "react";
import Navbar from "./Navbar";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "../styles/notFound.scss";
export default function NotFound(props) {
  const navigate = useNavigate();
  return (
    <motion.div
      className="notFound page"
      initial="initial"
      animate="in"
      exit="out"
      variants={props.pageVariants}
    >
      <Navbar />
      <p className="e404">404</p>
      <p className="oops">Oops! Sembra che ti sei perso</p>
      <button
        onClick={() => {
          navigate("/");
        }}
      >
        Portami indietro
      </button>
    </motion.div>
  );
}
