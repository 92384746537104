import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "../styles/productPicturesPopup.scss";

const variants = {
  open: { opacity: 1, y: 0, pointerEvents: "all" },
  closed: { opacity: 0, y: 100, pointerEvents: "none" },
};

const bGvariants = {
  open: { opacity: 1, pointerEvents: "all" },
  closed: { opacity: 0, pointerEvents: "none" },
};
export default function ProductPicturesPopup(props) {
  const [display, setDisplay] = useState(false);
  const [photos, setPhotos] = useState(null);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    setDisplay(props.display);
    setPhotos(props.photos);
    //console.log(props.photos);
  }, [props]);

  useEffect(() => {
    if (!photos) {
      return;
    }
    setSelected(photos[0]);
  }, [photos]);

  return (
    <motion.div
      initial="closed"
      animate={display ? "open" : "closed"}
      variants={bGvariants}
      className="productPicturesPopupBg"
      onClick={() => {
        props.handlePopup(false);
      }}
    >
      <motion.div
        onClick={(event) => {
          event.stopPropagation();
        }}
        initial="closed"
        animate={display ? "open" : "closed"}
        variants={variants}
        className="productPicturesPopup"
      >
        <div className="picSelector">
          {photos &&
            photos.map((photo, index) => (
              <div
                key={index}
                className="picSelectorThumb"
                style={{ backgroundImage: `url(${photo})` }}
                onClick={() => {
                  setSelected(photo);
                }}
              />
            ))}
        </div>
        <div className="picContainer">
          <div
            className="pic"
            style={{ backgroundImage: `url(${selected})` }}
          />
        </div>
        {/* <div className="productScrollList">
          {photos &&
            photos.map((photo) => (
              <div
                className="popupProductImage"
                style={{ backgroundImage: `url(${photo})` }}
              />
            ))}
        </div>
        <div className="productSelectedImage">
          {selected && <img src={selected} />}
        </div> */}
      </motion.div>
    </motion.div>
  );
}
