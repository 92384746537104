import React from "react";
import "../styles/contactPopup.scss";
import { motion } from "framer-motion";

const bgVariants = {
  open: {
    opacity: 1,
    pointerEvents: "all",
  },
  closed: {
    opacity: 0,
    pointerEvents: "none",
  },
};

const variants = {
  open: {
    opacity: 1,
    pointerEvents: "all",
    y: 0,
    scale: 1,
  },
  closed: {
    opacity: 0,
    pointerEvents: "none",
    y: -50,
    scale: 0.8,
  },
};

export default function ContactPopup(props) {
  return (
    <motion.div
      onClick={() => {
        props.handleClipboard(false);
      }}
      variants={bgVariants}
      initial="closed"
      animate={props.clipboard ? "open" : "closed"}
      className="toastBackground"
    >
      <motion.div
        variants={variants}
        initial="closed"
        animate={props.clipboard ? "open" : "closed"}
        className="toast"
      >
        {props.clipboard} è stato copiato!
      </motion.div>
    </motion.div>
  );
}
