import React, { useState } from "react";
import "../styles/navbar.scss";
import logo from "../assets/icons/logo.svg";
import hamburger from "../assets/icons/hamburger.svg";
import { Link } from "react-router-dom";
import Hamburger from "./Hamburger";
export default function Navbar() {
  const [display, setDisplay] = useState(false);
  function handleHamburger(val) {
    setDisplay(val);
  }
  return (
    <>
      <Hamburger display={display} />
      <div className="navbar">
        <Link to="/">
          <img src={logo} className="logo" alt="logo" />
        </Link>
        <img
          src={hamburger}
          className="hamburger"
          alt="hamburger"
          onClick={() => {
            handleHamburger(!display);
          }}
        />
        <div className="desktopNavigation">
          <Link to="/products">Tutti i prodotti</Link>
          <a href="https://www.makerdojo.it/chi-siamo.html">Chi siamo</a>
          <a href="https://www.makerdojo.it/contatti.html">Contatti</a>
        </div>
      </div>
    </>
  );
}
