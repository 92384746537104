import React from "react";
import "../styles/hero.scss";

export default function Hero() {
  return (
    <div className="hero">
      <p className="p1">maker</p>
      <p className="p2">DOJO</p>
      <p className="p3">learn & play</p>
      <div className="separatorBig" />
      <p className="p4">Technology to play</p>
      <div className="p5">
        <p>Con i </p>
        <p className="red bold">Kit MakerDojo </p>
        <p>impari le basi del </p>
        <p className="bold">coding</p>
        <p>, della </p>
        <p className="bold">robotica </p>
        <p>e dell’</p>
        <p className="bold">IoT</p>
        <p>, guidato passo dopo passo dai nostri esperti online.</p>
      </div>
    </div>
  );
}
