import React, { useState, useEffect } from "react";
import "../styles/itemCard.scss";
import { AndroidLogo, Student, Coin, Info, CaretRight } from "phosphor-react";
import { useNavigate } from "react-router-dom";
export default function ItemCard(props) {
  let navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [photos, setPhotos] = useState(null);

  useEffect(() => {
    setProduct(props.data.product);
    if (props.data.photos.length !== 0) {
      setPhotos(props.data.photos);
    }
  }, [props]);

  // useEffect(() => {
  //   console.log("product in product card", props.data.product.id, props.data);
  // }, [props]);

  return (
    <>
      {product && (
        <div className="itemCard">
          <div className="itemCardPicture">
            {photos && (
              <img
                onClick={() => {
                  navigate(`/product/${product.id}`);
                }}
                src={photos[0].link}
                alt="pic"
              />
            )}
          </div>
          <div className="itemDescription">
            <div>
              <AndroidLogo size={29} />
              <p style={{ color: "#66737C" }}>{product.brand}</p>
              <p className="elipsis">{product.name}</p>
            </div>
            <div>
              <Student size={29} />
              <p>Corso online di</p>
              <p style={{ color: "#E30613" }}>{product.course_length} ore</p>
            </div>
            <div>
              <Coin size={29} />
              <p>
                {parseInt(product.price)}
                <span className="price">€</span>
              </p>
              <p style={{ color: "#66737C" }}>spedizione inclusa</p>
            </div>
          </div>
          <div className="itemButtons">
            {/* <button
          className="infoButton"
          onClick={() => {
            navigate(`/product/${product.id}`);
          }}
        >
          <Info size={29} />
        </button> */}
            <button
              className="purchaseButton"
              onClick={() => {
                navigate(`/product/${product.id}`);
              }}
            >
              <p>Scopri di più</p>
              <CaretRight size={21} />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
