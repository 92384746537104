//PACKS
import ReactDOM from "react-dom";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { StoreProvider, createStore, action } from "easy-peasy";

//PAGES
import Home from "./components/Home";
import Product from "./components/Product";
import Form from "./components/Form";
import AllProducts from "./components/AllProducts";
import NotFound from "./components/NotFound";

//STYLES
import "./index.css";

//ASSETS
import "./fonts/Kollektif.ttf";
import "./fonts/KollektifBold.ttf";

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
  },
};

const store = createStore({
  cart: [],
  addToCart: action((state, payload) => {
    // console.log("payload", payload);
    state.cart.push(payload);
    // console.log(payload);
  }),
  resetCart: action((state, payload) => ({
    ...initialState,
  })),
});

const initialState = store.getState();

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <BrowserRouter>
        <AnimatePresence>
          <Routes>
            <Route path="/" element={<Home pageVariants={pageVariants} />} />
            <Route
              path="product/:productId"
              element={<Product pageVariants={pageVariants} />}
            />
            <Route
              path="acquista"
              element={<Form pageVariants={pageVariants} />}
            />
            <Route
              path="products"
              element={<AllProducts pageVariants={pageVariants} />}
            />
            <Route
              path="404"
              element={<NotFound pageVariants={pageVariants} />}
            />
            <Route path="/*" element={<Home pageVariants={pageVariants} />} />
          </Routes>
        </AnimatePresence>
      </BrowserRouter>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
