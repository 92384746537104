import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "../styles/hamburger.scss";

const variants = {
  open: {
    opacity: 1,
    pointerEvents: "all",
    y: 0,
  },
  closed: {
    opacity: 0,
    pointerEvents: "none",
    y: -100,
  },
};

export default function Hamburger(props) {
  return (
    <motion.div
      variants={variants}
      initial={"closed"}
      animate={props.display ? "open" : "closed"}
      className="hamburgerModal"
    >
      <Link to="/products">Tutti i prodotti</Link>
      <a href="https://www.makerdojo.it/chi-siamo.html">Chi siamo</a>
      <a href="https://www.makerdojo.it/contatti.html">Contatti</a>
    </motion.div>
  );
}
