//PACKS
import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useStoreActions } from "easy-peasy";
import { motion } from "framer-motion";
import { useWindowWidth } from "@react-hook/window-size";

//COMPONENTS
import Navbar from "./Navbar";
import ProductDescription from "./ProductDescription";
import ProductImage from "./ProductImage";
import Carousel from "./Carousel";
import ProductPicturesPopup from "./ProductPicturesPopup";
import Contact from "./Contact";

//STYLES
import "../styles/product.scss";

//ASSETS
import { api } from "../assets/json/api";
import {
  AndroidLogo,
  Student,
  Coin,
  CaretRight,
  CaretLeft,
  Baby,
} from "phosphor-react";

export default function Product(props) {
  // router hooks
  let params = useParams();
  let navigate = useNavigate();

  //window width
  const windowWidth = useWindowWidth();

  //store
  const addToCart = useStoreActions((actions) => actions.addToCart);

  //state
  const [display, setDisplay] = useState(null);
  const [product, setProduct] = useState(null);
  const [description, setDescription] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [popup, setPopup] = useState(false);

  //get item on mount
  useEffect(() => {
    async function getProduct() {
      try {
        const response = await axios.get(api + `products/${params.productId}`);
        //SET PRODUCT INFO
        setProduct(response.data.product);

        //SET DESCRIPTION
        setDescription(response.data.description);

        //SET PRODUCT PICTURES
        const tempPhotos = [];
        response.data.photos.forEach((photo) => {
          tempPhotos.push(photo.link);
        });
        setPhotos(tempPhotos);
        //----------------------
      } catch (e) {
        //console.log(e);
        navigate("/404");
      }
    }
    getProduct();
  }, []);

  useEffect(() => {
    //console.log(windowWidth);
    if (!product) {
      return;
    }
    if (windowWidth >= 1366) {
      setDisplay(desktop());
    } else if (windowWidth < 1366 && windowWidth >= 768) {
      setDisplay(tablet());
    } else {
      setDisplay(mobile());
    }
    //windowWidth <= 1366 ? setDisplay(mobile()) : setDisplay(desktop());
    //console.log("display:", display);
  }, [windowWidth, product, photos]);

  function displayCarousel() {
    if (photos.length == 0) {
      return;
    }

    let tempPhotoCards = [];

    photos.forEach((photo, index) => {
      tempPhotoCards.push(<ProductImage photo={photo} />);
    });

    return <Carousel cards={tempPhotoCards} cardHeight={300} />;
  }

  function handlePopup(isDisplayed) {
    setPopup(isDisplayed);
  }

  function mobile() {
    return (
      <>
        <Link to="/">
          <div className="backLink">
            <CaretLeft size={22} />
            <p style={{ color: "#66737C" }}>{product.brand}</p>
            <p className="productName">{product.name}</p>
          </div>
        </Link>
        <div className="separatorSmall" />
        <div className="productVideo">
          <iframe
            src={product.video}
            //src="https://player.vimeo.com/video/656103206"
            width="306"
            height="170"
            frameborder="0"
            byline={0}
            allow="autoplay; fullscreen"
            color="E30613"
          ></iframe>
        </div>
        <div className="separatorSmall" />
        {displayCarousel()}
        <div className="productDetails">
          <div>
            <Student size={29} />
            <p>Corso online di</p>
            <p style={{ color: "#E30613" }}>{product.course_length} ore</p>
          </div>
          <div className="productDetailsSeparator" />
          <div>
            <Coin size={29} />
            <p>
              {parseInt(product.price)}
              <span className="price">€</span>
            </p>
            <p style={{ color: "#66737C" }}>spedizione inclusa</p>
          </div>
          <div className="productDetailsSeparator" />
          <div>
            <Baby size={29} />
            <p>{product.age}</p>
            <p style={{ color: "#66737C" }}>anni</p>
          </div>
        </div>
        <div className="separatorSmall" />
        <div className="productDescriptionContainer">
          <div>
            <AndroidLogo size={29} />
            <p>Descrizione generale</p>
          </div>
          <div className="productDetailsSeparator" />
          <ProductDescription display="mobile" description={description} />
        </div>
        <div className="separatorSmall" />
        <button
          className="purchaseButton"
          onClick={() => {
            addToCart({ product, photos });
            navigate(`/acquista`);
          }}
        >
          <p>Acquista</p>
          <CaretRight size={21} />
        </button>
        <div className="separatorBig" />
        <Contact />
        <div className="separatorBig" />
      </>
    );
  }

  function tablet() {
    return (
      <>
        <Link to="/">
          <div className="backLink">
            <CaretLeft size={22} />
            <p style={{ color: "#66737C" }}>{product.brand}</p>
            <p className="productName">{product.name}</p>
          </div>
        </Link>
        <div className="separatorSmall" />
        <div className="productVideo">
          <iframe
            src={product.video}
            //src="https://player.vimeo.com/video/656103206"
            width="650"
            height="370"
            frameborder="0"
            byline={0}
            allow="autoplay; fullscreen"
            color="E30613"
          ></iframe>
        </div>
        <div className="separatorSmall" />
        {displayCarousel()}
        <div className="productDetails">
          <div>
            <Student size={29} />
            <p>Corso online di</p>
            <p style={{ color: "#E30613" }}>{product.course_length} ore</p>
          </div>
          <div className="productDetailsSeparator" />
          <div>
            <Coin size={29} />
            <p>
              {parseInt(product.price)}
              <span className="price">€</span>
            </p>
            <p style={{ color: "#66737C" }}>spedizione inclusa</p>
          </div>
          <div className="productDetailsSeparator" />
          <div>
            <Baby size={29} />
            <p>{product.age}</p>
            <p style={{ color: "#66737C" }}>anni</p>
          </div>
        </div>
        <div className="separatorSmall" />
        <div className="productDescriptionContainer">
          <div>
            <AndroidLogo size={29} />
            <p>Descrizione generale</p>
          </div>
          <div className="productDetailsSeparator" />
          <ProductDescription display="mobile" description={description} />
        </div>
        <div className="separatorSmall" />
        <button
          className="purchaseButton"
          onClick={() => {
            addToCart({ product, photos });
            navigate(`/acquista`);
          }}
        >
          <p>Acquista</p>
          <CaretRight size={21} />
        </button>
        <div className="separatorBig" />
        <Contact />
        <div className="separatorBig" />
      </>
    );
  }

  function desktop() {
    return (
      <div className="productContainer">
        <Link to="/">
          <div className="backLink">
            <CaretLeft size={22} />
            <p style={{ color: "#66737C" }}>{product.brand}</p>
            <p className="productName">{product.name}</p>
          </div>
        </Link>
        <div className="separatorBig" />
        <div className="productGrid">
          <div className="productVideo">
            <iframe
              src={product.video}
              //src="https://player.vimeo.com/video/656103206"
              width="640"
              height="355"
              frameBorder="0"
              byline={0}
              allow="autoplay; fullscreen"
              color="E30613"
            ></iframe>
          </div>
          <div
            className="productSecPic"
            style={{ backgroundImage: `url(${photos[0]})` }}
          >
            <p
              onClick={() => {
                handlePopup(true);
                console.log("ckick");
              }}
            >
              Mostra più foto
            </p>
          </div>
          {/* <button className="morePictures">
            <p>Mostra più foto</p>
            <CaretRight size={23} />
          </button> */}
          <div className="productDetails">
            <div>
              <Student size={30} />
              <p>Corso online di</p>
              <p style={{ color: "#E30613" }}>{product.course_length} ore</p>
            </div>
            <div className="productDetailsSeparator" />
            <div>
              <Coin size={30} />
              <p>
                {parseInt(product.price)}
                <span className="price">€</span>
              </p>
              <p style={{ color: "#66737C" }}>spedizione inclusa</p>
            </div>
            <div className="productDetailsSeparator" />
            <div>
              <Baby size={30} />
              <p>{product.age}</p>
              <p style={{ color: "#66737C" }}>anni</p>
            </div>
          </div>
          <button
            className="purchaseButton"
            onClick={() => {
              addToCart({ product, photos });
              navigate(`/acquista`);
            }}
          >
            <p>Acquista</p>
            <CaretRight size={23} />
          </button>
        </div>
        <div className="separatorBig" />
        <div className="productDescriptionContainer">
          <div className="productDescriptionHeader">
            <AndroidLogo size={29} />
            <p>Descrizione generale</p>
          </div>
          <div className="productDetailsSeparator" />
          <ProductDescription display="desktop" description={description} />
        </div>
        <div className="separatorBig" />
        <Contact />
        <div className="separatorBig" />
      </div>
    );
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={props.pageVariants}
      className="page product"
    >
      {product && (
        <>
          <Navbar />
          <ProductPicturesPopup
            display={popup}
            handlePopup={handlePopup.bind(this)}
            photos={photos}
          />
          {display}
        </>
      )}
    </motion.div>
  );
}
