//PACKS
import React from "react";
import { motion } from "framer-motion";

//COMPONENTS
import Navbar from "./Navbar";
import Hero from "./Hero";
import Description from "./Description";
import Items from "./Items";
import Contact from "./Contact";

//ASSETS
import { paragraphs } from "../assets/json/parahraphs";

export default function Home(props) {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={props.pageVariants}
      className="page"
    >
      <Navbar />
      <Hero />
      <div className="separatorBig" />
      <Description data={paragraphs.description} />
      <div className="separatorBig" />
      <Items data={paragraphs.items} />
      <div className="separatorBig" />
      <Contact />
      <div className="separatorBig" />
    </motion.div>
  );
}
