import React, { useState } from "react";
import Paragraph from "./Paragraph";
import {
  Phone,
  WhatsappLogo,
  EnvelopeSimple,
  CaretRight,
  Copy,
  Buildings,
  TagSimple,
  MapPin,
  Cookie,
  Key,
  Newspaper,
} from "phosphor-react";
import "../styles/contact.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContactPopup from "./ContactPopup";

const contactTitle = {
  title1: "Contatto",
};

const contact = {
  phone: "379 1654181",
  whatsapp: { title: "Chatta su WhatsApp", link: "https://wa.me/393791654181" },
  email: "info@makerdojo.it",
};

const company = {
  name: "SET S.R.L",
  url: "https://www.societaeconomiaterritorio.it/",
  address: {
    name: "Largo Padre Renzo Spadoni, PISA",
    link: "https://www.google.com/maps/place/SET/@43.7168041,10.3891198,17z/data=!3m1!4b1!4m5!3m4!1s0x12d59174dba7a789:0xfdf5caa74c553cfb!8m2!3d43.7168045!4d10.3913074?hl=en-IT",
  },
  tva: "P.IVA 02295570424",
};

export default function Contact() {
  const [clipboard, setClipboard] = useState(false);
  function handleClipboard(x) {
    setClipboard(x);
  }
  return (
    <>
      <ContactPopup
        clipboard={clipboard}
        handleClipboard={handleClipboard.bind(this)}
      />
      <div className="contact">
        <Paragraph
          data={contactTitle}
          onCopy={() => {
            console.log("copied");
          }}
        />
        <div className="separatorSmall" />
        <div className="contactContainer">
          <CopyToClipboard
            text={contact.phone}
            onCopy={() => {
              setClipboard("Il numero di telefono");
            }}
          >
            <div className="contactOption">
              <div>
                <Phone size={30} />
                <p>{contact.phone}</p>
              </div>
              <Copy size={21} />
            </div>
          </CopyToClipboard>
          <a href={contact.whatsapp.link}>
            <div className="contactOption">
              <div>
                <WhatsappLogo size={30} />
                <p>{contact.whatsapp.title}</p>
              </div>
              <CaretRight size={21} />
            </div>
          </a>
          <CopyToClipboard
            text={contact.email}
            onCopy={() => {
              setClipboard("l'indirizzo email");
            }}
          >
            <div className="contactOption">
              <div>
                <EnvelopeSimple size={30} />
                <p>{contact.email}</p>
              </div>
              <Copy size={21} />
            </div>
          </CopyToClipboard>
        </div>
        <div className="separatorSmall" />
        {/* ---------------------------------------------------------------- */}
        <div className="contactContainer">
          <a
            href="https://www.iubenda.com/privacy-policy/93907653"
            className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
            title="Privacy Policy "
          >
            <div className="contactOption">
              <div>
                <Key size={30} />
                <p>Privacy Policy</p>
              </div>
              <CaretRight size={21} />
            </div>
          </a>
          <a
            href="https://www.iubenda.com/privacy-policy/93907653/cookie-policy"
            className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
            title="Cookie Policy "
          >
            <div className="contactOption">
              <div>
                <Cookie size={30} />
                <p>Cookie Policy</p>
              </div>
              <CaretRight size={21} />
            </div>
          </a>
          <a
            href="https://www.iubenda.com/termini-e-condizioni/93907653"
            className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
            title="Termini e Condizioni "
          >
            <div className="contactOption">
              <div>
                <Newspaper size={30} />
                <p>Termini e Condizioni</p>
              </div>
              <CaretRight size={21} />
            </div>
          </a>
        </div>
        <div className="separatorSmall" />
        <div className="contactContainer">
          <a href={company.url} target="_blank">
            <div className="contactOption">
              <div>
                <Buildings size={30} />
                <p>{company.name}</p>
              </div>
              <CaretRight size={21} />
            </div>
          </a>
          <a href={company.address.link} target="_blank">
            <div className="contactOption">
              <div>
                <MapPin size={30} />
                <p>{company.address.name}</p>
              </div>
              <CaretRight size={21} />
            </div>
          </a>
          <div className="contactOption">
            <div>
              <TagSimple size={30} />
              <p>{company.tva}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
