//PACKS
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//STYLES
import "../styles/form.scss";

export default function CartItem(props) {
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [price, setPrice] = useState("");

  useEffect(() => {
    if (props.data.product.price === props.totPrice && props.totPrice) {
      setPrice(
        <div className="itemPrice">
          <p>
            {parseInt(props.data.product.price)}
            <span className="price">€</span>
          </p>
        </div>
      );
    } else {
      setPrice(
        <>
          <div className="itemOldPrice">
            <p>
              <span>
                {parseInt(props.data.product.price)}
                <span className="price">€</span>
              </span>
            </p>
          </div>
          <div className="itemDiscountedPrice">
            <p>
              <span>
                {props.totPrice}
                <span className="price">€</span>
              </span>
            </p>
          </div>
        </>
      );
    }
  }, [props.data.price, props.totPrice]);

  useEffect(() => {
    setItem(props.data.product);
    if (props.data.photos.length > 0) {
      setPhoto(props.data.photos[0]);
    }
  }, [props]);

  useEffect(() => {
    console.log("item in cart", props);
  }, [props]);

  return (
    item && (
      <div
        key={props.key}
        className="cartItem"
        key={item.id}
        onClick={() => {
          navigate(`/product/${item.id}`);
        }}
      >
        <div
          className="cartItemImage"
          style={{ backgroundImage: `url(${photo})` }}
        />
        <div className="cartItemFirstLine">
          <div className="itemTitle">
            <p>{item.name}</p>
          </div>
          {price}
        </div>
        <div className="itemDescription">
          <p>{item.description}</p>
        </div>
      </div>
    )
  );
}
