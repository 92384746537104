//PACKS
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

//STYLES
import "../styles/carousel.scss";

export default function Carousel(props) {
  //STATES
  const [cards, setCards] = useState([]);
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(375);
  const [height, setHeight] = useState(375);
  const [position, setPosition] = useState(0);

  //LOAD ITEMS
  useEffect(() => {
    setCards(props.cards);
    setLength(props.cards.length);
    setHeight(props.cardHeight + 40);
  }, [props]);

  useEffect(() => {
    console.log(window.innerWidth);
    setWidth(window.innerWidth);
  }, [window.innerWidth]);

  function handleIndicator() {
    let temp = [];
    cards.forEach((card, index) =>
      temp.push(
        <motion.div
          animate={{
            width: index === Math.abs(position / width) ? "18px" : "6px",
            opacity: index === Math.abs(position / width) ? 1 : 0.5,
          }}
          key={index}
        />
      )
    );
    return temp;
  }

  function resetPos() {
    let pos = position;
    setPosition(false);
    setPosition(pos);
  }

  //SETS DIRECTION BASED ON OFFSET GIVEN BY DRAGGABLE
  function handleOnDragEnd(offset) {
    //INPUT
    let x = offset.x;
    let y = offset.y;

    if (Math.abs(y) < 100) {
      //MATH
      // console.log("START----------");
      let direction = Math.sign(x);
      // console.log("direction", direction);
      let times = Math.floor(Math.abs(x) / width);
      let rest = Math.abs(x) % width;
      if (rest > 0.3 * width) {
        times++;
      }
      // console.log("swipe x", times);
      // console.log(times);
      //MOVEMENT
      if (times === 0) {
        resetPos();
      }
      if (direction === -1) {
        let nextPos = position - width * times;
        // console.log("nextPos", nextPos);
        if (Math.abs(nextPos) > width * (length - 1)) {
        } else {
          setPosition(nextPos);
        }
      } else if (direction === 1) {
        let nextPos = position + width * times;
        // console.log("nextPos", nextPos);
        if (nextPos <= 0) {
          setPosition(nextPos);
        } else {
        }
      }
    } else {
      resetPos();
    }
    // console.log("----------STOP");
  }

  return (
    <div className="carouselContainer" style={{ height: height + 20 + "px" }}>
      <motion.div
        className="carousel"
        dragConstraints={{ right: 0, left: -width * (length - 1) }}
        drag="x"
        animate={{ x: position }}
        onDragEnd={(event, info) => handleOnDragEnd(info.offset)}
        // onDragStart={(event, info) => console.log("started")}
        // transition={{ type: "spring", bounce: 0.2 }}
      >
        {cards}
      </motion.div>
      <div className="carouselIndicator">{handleIndicator()}</div>
    </div>
  );
}
