import React from "react";
import "../styles/description.scss";
import { description } from "../assets/json/description.js";
import Paragraph from "./Paragraph";
import DescriptionCard from "./DescriptionCard";

export default function Description(props) {
  return (
    <div className="descriptionParagraph">
      <Paragraph data={props.data} />
      <div className="descriptionCards">
        {description &&
          description.map((card, index) => (
            <li key={index} style={{ listStyle: "none" }}>
              <DescriptionCard data={card} />
            </li>
          ))}
      </div>
    </div>
  );
}
