import React from "react";
import logo from "../assets/icons/logo.svg";
import loading from "../assets/icons/loading.svg";
import "../styles/loading.scss";
import { motion } from "framer-motion";

const variants = {
  open: { opacity: 1, pointerEvents: "all" },
  closed: { opacity: 0, pointerEvents: "none" },
};

export default function Loading(props) {
  return (
    <motion.div
      variants={variants}
      initial="closed"
      animate={props.sent ? "open" : "closed"}
      className="loading"
    >
      <img className="logo" src={logo} alt="logo" />
      <div>
        <h1>Vieni reindirizzato a Stripe</h1>
        <p>Per favore non lasciare la pagina</p>
      </div>
      <img style={{ width: "24px" }} src={loading} alt="loading" />
    </motion.div>
  );
}
